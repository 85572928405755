import CategoryLink from '@jetshop/ui/CategoryLink';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { theme } from '../../../Theming/Theming';
import { ScrollBox } from '../../../ui/ScrollBox';
import deepCategoriesQuery from '../Categories/DeepCategoriesQuery.gql';
import SubMenu from './SubMenu';

const DesktopNavWrapper = styled('div')`
  height: ${theme.desktopHeader.nav.height.lg + 'px'};
  width: calc(100% - 450px);
  ${theme.above.xxl} {
    width: calc(100% - 450px);
  }
  //position: relative;
  z-index: 0;
  margin-left: 10px;
  ${theme.above.xl} {
    margin-left: 30px;
  }
  ${theme.only.md} {
    height: ${theme.desktopHeader.nav.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.nav.height.scroll + 'px'};
  }

  .slider-box-wrapper {
    height: 100%;
    .slider-track {
      height: 100%;
      .slider-items {
        height: 100%;
      }
    }
    .nav-buttons button {
      &.standard-nav {
        top: calc(50% - 10px);
      }
      width: 20px;
      height: 20px;
      svg {
        width: 11px;
        height: 11px;
      }
    }
  }
`;

const List = styled('ul')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  &.sub-menu-open {
    > li:not(.sub-menu-open) {
      //opacity: 0.7;
    }
  }

  a {
    &:hover {
      color: ${theme.colors.primary};
    }
  }

  > li {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 ${theme.desktopHeader.nav.padding[0]};
    ${theme.above.xxl} {
      padding: 0 ${theme.desktopHeader.nav.padding[1]};
    }
    height: ${theme.desktopHeader.nav.height.lg + 'px'};
    ${theme.only.md} {
      height: ${theme.desktopHeader.nav.height.md + 'px'};
    }
    > a {
      position: relative;
      padding: 1rem 0;
      display: block;
      > ${theme.desktopHeader.nav.nameTag} {
        display: block;
        text-transform: uppercase;
        font-size: ${theme.desktopHeader.nav.fontSize[0]};
        font-weight: ${theme.desktopHeader.nav.fontWeight};
        color: ${theme.desktopHeader.color};
        ${theme.above.xxl} {
          font-size: ${theme.desktopHeader.nav.fontSize[1]};
        }
      }
      &.active {
        //color: ${theme.colors.primaryLight};
      }
      &.selected {
        //color: ${theme.colors.primary};
      }
    }

    &.sub-menu-open {
      .sub-menu-wrapper {
        display: block;
      }
    }
  }
  &[data-scrolling='true'] {
    > li {
      height: ${theme.desktopHeader.nav.height.scroll + 'px'};
    }
  }
`;

const NavName = styled(theme.desktopHeader.nav.nameTag)`
  font-size: 1.2rem !important;
`;

const CategoryWithSubs = styled(CategoryLink)``;

const SubMenuMarker = styled('span')`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 2px;
  display: block;
  background: ${theme.desktopHeader.nav.barHoverColor};
`;

function handleCatOpen(e, category, setActiveCategory, setSubMenu) {
  if (category.hasSubcategories) {
    e.preventDefault();
    setActiveCategory(category);
    setSubMenu(true);
  } else setSubMenu(false);
}

const NavItem = ({
  category,
  active,
  setActiveCategory,
  subMenuOpen,
  setSubMenu,
  scrolling,
}) => {
  const [fetchCategories, setFetch] = useState(false);
  return (
    <li
      key={category.id}
      className={subMenuOpen && active ? 'sub-menu-open' : ''}
    >
      {category.hasSubcategories ? (
        <CategoryWithSubs
          category={category}
          className="category-with-subs"
          onMouseEnter={(e) => {
            setFetch(true);
            //handleCatOpen(e, category, setActiveCategory, setSubMenu);
          }}
          onClick={(e) =>
            handleCatOpen(e, category, setActiveCategory, setSubMenu)
          }
        >
          <NavName>{category.name}</NavName>
          {subMenuOpen && active && (
            <SubMenuMarker data-scrolling={scrolling} />
          )}
        </CategoryWithSubs>
      ) : (
        <CategoryLink
          category={category}
          onClick={(e) =>
            handleCatOpen(e, category, setActiveCategory, setSubMenu)
          }
        >
          <NavName>{category.name}</NavName>{' '}
        </CategoryLink>
      )}
      {fetchCategories && (
        <Query
          variables={{ root: category.id, levels: 1 }}
          query={deepCategoriesQuery}
        >
          {(result) => {
            const { data } = result;
            const category =
              (data && data.categories && data.categories[0]) || false;
            if (subMenuOpen && active && category) {
              return (
                <SubMenu
                  category={category}
                  setActiveCategory={setActiveCategory}
                  setSubMenu={setSubMenu}
                  scrolling={scrolling}
                />
              );
            } else return null;
          }}
        </Query>
      )}
    </li>
  );
};

const DesktopNav = ({ categoryTreeRoots, scrolling }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [subMenuOpen, setSubMenu] = useState(false);
  return (
    <DesktopNavWrapper data-scrolling={scrolling}>
      <ScrollBox>
        <List
          data-scrolling={scrolling}
          className={subMenuOpen && 'sub-menu-open'}
        >
          {categoryTreeRoots.map((category, index) => (
            <NavItem
              key={index}
              category={category}
              active={activeCategory && activeCategory.id === category.id}
              setActiveCategory={setActiveCategory}
              subMenuOpen={subMenuOpen}
              setSubMenu={setSubMenu}
              scrolling={scrolling}
            />
          ))}
        </List>
      </ScrollBox>
    </DesktopNavWrapper>
  );
};

export default DesktopNav;
