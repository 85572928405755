import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../Theming/Theming';
import CampaignBar from './CampaignBar';

const ContentWrapper = styled('main')`
  background: #fff;
  flex: 1 1 auto;
  //padding-top: 1rem;
`;

export const ContentPadding = `
  padding-bottom: 2rem;
  ${theme.above.md} {
    padding-bottom: 4rem;
  }
`;

const MainBackground = styled('div')``;

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <CampaignBar />
      <MainBackground>{children}</MainBackground>
    </ContentWrapper>
  );
};

export default Content;
