import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { theme } from '../../Theming/Theming';
import MaxWidth from '../MaxWidth';
import KlarnaOnSite from './KlarnaOnSite';
import NewsletterBox from './Newsletter/NewsletterBox';
//import KlarnaOnSite from './KlarnaOnSite';

const FooterWrapper = styled('footer')`
  margin-top: 3%;
  background: ${theme.footer.background};
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 0 0;
    color: ${theme.footer.color};
  }
  .footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: ${theme.footer.topFooterBackground};
    padding: 3rem 0;
    > div {
      width: 50%;
      padding: 1rem;
      text-align: center;
      ${theme.above.md} {
        width: 33%;
      }
      img {
        margin-bottom: 7px;
        max-width: 40px;
      }
    }
  }
  .footer-bottom {
    padding: 3rem 2rem;
    background: #f3f3f3;
    > div {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      > div {
        width: 33.3333%;
        display: flex;
        align-items: center;
        justify-content: center;
        ${theme.below.md} {
          width: 100%;
          margin: 1rem 0;
        }
        ${theme.above.lg} {
          &:first-child {
            justify-content: flex-start;
          }
        }
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 0.5rem;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.above.md} {
      width: 40%;
      text-align: left;
    }
    ${theme.below.md} {
      text-align: center;
    }
    ${theme.above.xl} {
      width: 25%;
      padding: 3rem 3rem;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

const Footer = () => {
  const { footer1, footer2, footer3, footer4, footerTop, footerBottom } =
    useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>

        <div className="footer-content">
          <div className="footer-top">{footerTop}</div>
          <FooterContentWrapper>
            <div>{footer1}</div>
            <div>{footer2}</div>
            <div>{footer3}</div>
            <div>{footer4}</div>
          </FooterContentWrapper>
          <div className="footer-bottom">
            <MaxWidth>
              <KlarnaOnSite />
              {footerBottom}
            </MaxWidth>
          </div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
